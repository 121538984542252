import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import TitlePage from '../components/TitlePage'
import useCategories from '../components/useCategories'
import * as S from '../components/ListWrapper/styled'
import SocialShare from '../components/SocialShare'
import theme from '../theme'
import SectionItem from '../components/SectionItem'

const Index = ({ data }) => {
    const {
        hello,
        subline,
        pageTitle,
    } = useCategories()

    console.log('data', JSON.stringify(data.allAirtable.edges))

    const sectionList = data.allAirtable.edges

    const centerText = { textAlign: 'center' }

    return (
        <div className="homepage">
            <SEO title={pageTitle}
                 image={'https://res.cloudinary.com/uequations/image/upload/v1591745871/news-site/christine-sandu-4l63PeiANu4-unsplash.jpg'}/>
            <TitlePage text={hello}/>
            <div style={centerText}>
                <p>{subline}</p>
            </div>
            <br/>

            <SocialShare pageTitle={pageTitle} subline={subline} url={'https://news.uequations.com'}/>

            <hr style={{ margin: `2rem 0` }}/>

            <h2 style={centerText}>
                <strong>SECTIONS</strong>
            </h2>

            <br/>

            <S.ListWrapper>
                {sectionList.map(
                    (section, i) => (
                        <SectionItem
                            slug={`section/${section.node.data.path}`}
                            backgroundColor={theme.colors.primary}
                            name={section.node.data.name}
                            description={section.node.data.description}
                            imageURL={section.node.data.image_url}
                            title={section.node.data.title}
                        />
                    ),
                )}
            </S.ListWrapper>

            <br/>

        </div>
    )
}

export default Index

export const query = graphql`
    query Sections {
        allAirtable(filter: {table: {eq: "Section"}}, sort: {fields: data___updated_at, order: DESC}) {
            edges {
                node {
                    data {
                        description
                        image_url
                        link_post
                        name
                        path
                        title
                    }
                }
            }
        }
    }
`
