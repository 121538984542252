import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const SectionItem = ({
                         backgroundColor,
                         description,
                         imageURL,
                         slug,
                         name,
                         title,
                     }) => {

    return (
        <S.PostItemLink to={slug}>
            <S.PostItemWrapper>
                {imageURL && (
                    <S.PostItemExtImg
                        src={imageURL}
                        alt={title}
                    />
                )}
                {!imageURL && (
                    <S.PostItemExtImg
                        alt={title}
                        src={''}
                    />
                )}

                <S.PostItemInfo>
                    <S.PostItemTag backgroundColor={backgroundColor}>
                        {name}
                    </S.PostItemTag>
                </S.PostItemInfo>
            </S.PostItemWrapper>
        </S.PostItemLink>
    )
}

SectionItem.propTypes = {
    backgroundColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageURL: PropTypes.string,
    slug: PropTypes.string,
    name: PropTypes.string,
}

export default SectionItem
